@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Serif");
@import url("https://fonts.googleapis.com/css2?family=Roboto");
/* 
body {
  background-image: url("./image0.png");
} */

.App {
  text-align: center;
  width: 99.3%;
}
.Header {
  display: flex;
  flex-direction: row;
  width: 99%;
  justify-content: space-between;
  padding: 0.5%;
  margin-right: 0;
}
.NameAndToggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.Navbar {
  height: 12vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
}
.Navbar img {
  height: 9vh;
}
.NavbarPosition {
  position: fixed;
  width: 100%;
  box-shadow: 2px 2px 2px grey;
  z-index: 1000;
}

.NameAndToggle h1 {
  font-size: 50px;
  margin: 25px;
}
.NavLinks {
  font-size: 25px;
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 2%;
  padding-right: 4%;
}
.NavLinks table {
  width: 65%;
}

.text1 {
  padding-right: 10vw;
}
.text1 h1 {
  font-size: 3em;
}
.text1 h2 {
  font-size: 1.5em;
  text-align: left;
}
.ViewMore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10vw;
}
.ViewMore:hover {
  cursor: pointer;
}
.ViewMore p {
  margin-bottom: 0px;
}

/* About Me */

@media only screen and (min-width: 1024px) {
  .text1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px;
    width: 95%;
  }
}
@media only screen and (max-width: 1023px) {
  .image1 {
    height: 25vh;
  }
}

/* Bio */

.Bio {
  min-height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(0.6em + 0.6vw)
}
.BioContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.BioText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40vw;
  padding-left: 8vw;
}
.BioText p {
  text-align: end;
  font-size: 1.15em;
}

/* Projects */

.Projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.Projects img {
  width: 30vw;
}
.Projects footer {
  padding-top: 15px;
}
.ProjectsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

/* Resume */

.Resume {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 85vh;
}
/* Experience */
.Experience {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 81vh;
}
/* Freelance */

.FreelanceServices {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 81vh;
}

/* Contact Me */

.ContactMe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 85vh;
}
.contacts {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.email {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.contact-form {
  display: flex;
  flex-direction: column;
  width: 40vw;
}
.contact-form textarea {
  height: 10vh;
}
.github {
  display: flex;
}
.twitter {
  display: flex;
}
.email img {
  height: 6vh;
}
.github img {
  width: 31vh;
}
.twitter img {
  border-radius: 50%;
  width: 10vh;
}

/* 217882 */
